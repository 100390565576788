body {
  background: url("../src/app/assets/img/3Crosses.jpg") no-repeat fixed center;
  background-size: 100% 100%;
  font-family: papyrus, sans-serif;
  color: #00008B;
}

.papyrus {
  font-family: papyrus, sans-serif;
  color: #00008B;
  font-weight: bold; 
}

.departmentText {
  flex: 1;
  justify-content: center;
  font-size: 20px;
}

.header {
  flex: 1;
  justify-content: center;
  text-align: center;
}

.jumbotron {
  background-image: linear-gradient(to right, #00008B, #D3D3D3, #F5FFFA);
  color: #00008B;

  @media screen and (max-width: 576px){
    background-image: linear-gradient( #00008B, #D3D3D3, #F5FFFA);
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    background-image: linear-gradient( #00008B, #D3D3D3, #F5FFFA);
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
   
  }

  @media screen and (min-width: 1200px){
    
  }
}

h1,
h2,
h3,
.card-body > .card-title {
  font-family: papyrus, sans-serif;
}

#biLink {
  margin: 1rem;
  font-size: 5rem;
  text-align: center;

  @media screen and (max-width: 576px){
    font-size: 2.3rem;
    margin-top: 3rem;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    
  }
}

#bibleIcon {
  width: 35%;

  @media screen and (max-width: 576px){
    width: 50%;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    
  }
}

h6 {
  color:#00008B;
  font-size: 1rem;
  font-family: papyrus, sans-serif;
  text-align: center;
  margin-top: -1rem;
}

.bibleTitle {
  color:#00008B;
  font-weight: bold;
  font-size: 8rem;

  @media screen and (max-width: 576px){
    font-size: 3rem;
    margin-top: -3rem;
    margin-bottom: -0.5rem;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    font-size: 5rem;
    margin-top: -3rem;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    font-size: 7rem;
    margin: -2rem 0rem;
  }
}

.btn.testamentButton {
  color: #FAEED7;
  background-color: #00008B;
  font-family: papyrus, sans-serif;
  font-weight:900;
  border: 3px solid #FAEED7;
  padding: 1rem;

  @media screen and (max-width: 576px){
    margin: 4px;
    font-size: 1rem;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    margin: 2rem;
    font-size: 1.5rem;
  }

  @media screen and (min-width: 1200px){
    margin: 4rem;
    font-size: 2rem;
  }
}

#otBookLayout {
  text-align: left;
  
  @media screen and (max-width: 576px){
    text-align: center;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    
  }

  @media screen and (min-width: 1200px){
    
  }
}

#ntBookLayout {
  text-align: right;
  
  @media screen and (max-width: 576px){
    text-align: center;
  }

  @media screen and (min-width: 576.02px) and (max-width: 992px){
    
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    
  }

  @media screen and (min-width: 1200px){
    
  }
}

.card-title {
  font-weight: 500;
  font-size: 1.25rem;
}

h2 {
  color: #3046c5;
}

.card-img-overlay .card-title {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.row-content {
  padding: 50px 0;
  border-bottom: 1px ridge;
  min-height: 200px;
}

.site-footer {
  background-image: linear-gradient(#cec8ff, white);
  padding: 20px 20px;
  margin-top: 2rem;
}

.navbar-brand {
  max-width: 4rem;
  margin-right: 2rem;
}

 .navbar-brand img {
  width: 100%;
  height: 100%;
  margin-right: 2rem;
  max-width: 1rem;
}

.navbar-dark {
  background-color: lightgray;
  margin-bottom: 2rem;
  min-height: 3rem;
  margin-top:0;
}

.page-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: 300ms ease-out;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: 300ms ease-in;
}

#footerIcon {
  color: #00008B;
}

#contact {
  padding: 10px;
  color: lightgray;
  font-family: Papyrus, 'Times New Roman', Times, serif;
  font-size: 20px;
  background-color: #00008B;
  border-radius: 40px;
}

.jumbotronFooter {
  margin: 0;
  padding: 0;
  background-image: linear-gradient(to right, #F5FFFA, #D3D3D3, #00008B);
  color: #00008B;
}

#follow {
  text-align: center;
}

.navbar .navbar-toggler:focus{
  color: #00008B;
}

.picTitles {
  flex: 1; 
  justify-content: center;
  font-size: 20px;
  font-family: papyrus;
  color: #00008B; 
  font-weight: bold;
}

.bodyFont {
  flex: 1; 
  justify-content: center; 
  font-size: 25px;
  font-family: papyrus; 
  color: #00008B; 
  font-weight: bold;
}

